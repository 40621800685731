import Outline from "../../components/outline/Outline";
import Signup from "../../components/signup/Signup";
import Intro from "../../components/intro/Intro";
import Contact from "../../components/contact/Contact";
import "./Home.scss";

function Home() {
  
  return (
      <div className="home">
          <div className="sections">
            <Intro />
            <Outline />
            <Signup />
            <Contact />
          </div>
      </div>
  );
}

export default Home;