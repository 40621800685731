import Home from "./pages/home/Home";
import TheOthers from "./pages/the_others/TheOthers";
import DOPBackmatter from "./pages/dop_backmatter/DOP_Backmatter";
import EmailConfirmation from "./pages/email_confirmation/Email_Confirmation";
import { Route, BrowserRouter as Router} from "react-router-dom";
import "./App.scss";

function App() {
  
  return (
    <Router>
    
      <div className="app">
          <div className="sections">
              <Route path="/" exact component={Home} />
              <Route path="/the_others" component={TheOthers} />
              <Route path="/newsletter-sign-up" component={DOPBackmatter} />
              <Route path="/confirmation" component={EmailConfirmation} />
          </div>
      </div>
    </Router>

  );
}

export default App;