import React from 'react'
import './Intro.scss';
import Hamburger from '../hamburger/Hamburger';
import Menu from "../menu/Menu";
import { init } from 'ityped';
import { useState, useRef, useEffect } from "react";

export default function Intro() {
    const [menuOpen, setMenuOpen] = useState(false)
        
    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current, { 
            showCursor: true,
            backDelay: 1500,
            strings: ['Author ', 'Storyteller ', 'Myth-Maker ' ] 
        })
    }, [])

    return (
        <div>
            <div className="intro-container" id="home">
                <div className="background-img">

                    <div className="hamburger-menu">
                        <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                    </div>
                    
                    <div className="logo">
                        <img src="assets/PNShafa_Logo.png" alt="" />
                    </div>

                    <div className="wrapper">
                        <h2>Oh, hello humanoid.</h2>
                        <h1>I'm P.N. Shafa.</h1>
                        <h3><span ref={textRef}></span></h3>
                    </div>

                    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>
            </div>
        </div>
    )
}
