import React from 'react'
import './Signup.scss';
import HamburgerAlt from '../hamburger/Hamburger-Alt';
import Menu from "../menu/Menu";
import { useState } from "react";

export default function Signup() {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div>
            <div className="home-container" id="signup">
                <div className="hero-img">

                    <div className="hamburger-menu">
                        <HamburgerAlt menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                    </div>
                    <div className="signup-container">
                        <div className="signup-form">
                            <div class="ml-form-embed"
                                data-account="2495824:w5x8z5w2r4"
                                data-form="3947551:v5n4b0">
                            </div>
                        </div>
                    </div>
                    
                    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>
            </div>
        </div>
    )
}
