import React from 'react'
import './Email_Confirmation.scss';

export default function DOP_Backmatter() {
        
    return (
        <div>
            <div className="confirmation-container" id="confirmation">
                <div className="background-img">
                    <div className="logo-confirmation-page">
                        <img src="assets/PNShafa_Logo.png" alt="" />
                    </div>

                    <div className="confirmation-text">
                        <h2>Welcome to the P.N. Shafa Reader's Club</h2>
                        <p>Your email has been confirmed. Here's to good life choices.</p>
                        <p>Keep an eye on your inbox for a new email the first Wednesday of the month, 3pm Pacific Time.</p>
                    </div>
                    <p className="home-button"><a href="/">Home</a></p>
                </div>
            </div>
        </div>
    )
}