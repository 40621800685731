import React from 'react'
import './DOP_Backmatter.scss';

export default function DOP_Backmatter() {
        
    return (
        <div>
            <div className="intro-container" id="intro">
                <div className="background-img">
                    <div className="logo-landing-page">
                        <img src="assets/PNShafa_Logo.png" alt="" />
                    </div>

                    <div className="embedded-form">
                        <div class="ml-form-embed"
                            data-account="2495824:w5x8z5w2r4"
                            data-form="4161409:e2t7b0">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}