import './Menu.scss';

export default function Menu({ menuOpen, setMenuOpen }) {
    return (
        <div className={"menu " +(menuOpen && "active")}>
            <ul>
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#home" className="menu-item">Home</a>
                </li>
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#outline" className="menu-item">Descendants Of Power</a>
                </li>
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#signup" className="menu-item">Readers' Club</a>
                </li>
                <li onClick={()=>setMenuOpen(false)}>
                    <a href="#contact" className="menu-item">Contact</a>
                </li>
            </ul>
        </div>
    )
}
