import React from 'react'
import { useState } from "react";
import './Contact.scss';
import Hamburger from '../hamburger/Hamburger';
import Menu from "../menu/Menu";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoodreads } from "@fortawesome/free-brands-svg-icons";

export default function Contact() {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <div>
            <div className="contact-container" id="contact">
                <div className="contact-background-img">

                    <div className="hamburger-menu">
                        <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                    </div>
                    

                    <div className="contact-wrapper">
                        <h1>Who is P.N. Shafa?</h1>
                        <p>
                            P.N. is an Iranian-American immigrant living in Melbourne, Australia. 
                            As a child of immigrants, now an immigrant in her own right, she writes stories that explore otherness and growth through alienation. 
                            She is particularly fascinated by the narrative of intergenerational consequences, and can be found hunched over her notebook, 
                            scrawling in unreadable handwriting on the growth and demise of societal systems and the snowball effect of individual intention on human civilization. 
                        </p>
                        <p>
                            When not working as a creative herself, she spends her time supporting other creatives in their marketing and outreach efforts. 
                            She believes that all writers and contributors to the cultural narrative (including herself) 
                            have a responsibility to create works which will help the humans learn to cooperate and grow as a species.
                        </p>
                        <p>
                            ❃ ❃ ❃
                        </p>
                        <p>
                            Follow me on:
                        </p>
                        <a href="https://www.goodreads.com/nataliashafa" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faGoodreads} className="icon"/>
                        </a>
                        <p className="work-with-me">
                            Work with me at <a href="https://nativehumanoid.com" target="_blank" rel="noreferrer">Native Humanoid</a>. 
                        </p>
                    </div>

                    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>
            </div>
        </div>
    )
}