import React from 'react'
import './TheOthers.scss';
import { init } from 'ityped';
import { useRef, useEffect } from "react";

export default function TheOthers() {
        
    const textRef = useRef();

    useEffect(()=>{
        init(textRef.current, { 
            showCursor: true,
            backDelay: 1500,
            strings: ["Here's your bonus chapter...  Get it while it's hot.  " ] 
        })
    }, [])

    return (
        <div>
            <div className="intro-container" id="intro">
                <div className="background-img">
                    <div className="logo-landing-page">
                        <img src="assets/PNShafa_Logo.png" alt="" />
                    </div>

                    <div className="embedded-form">
                        <h3><span ref={textRef}></span></h3>
                        <div class="ml-form-embed"
                            data-account="2495824:w5x8z5w2r4"
                            data-form="4161406:g2i1m6">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}