import './Hamburger.scss';

export default function Hamburger({menuOpen, setMenuOpen}) {
    return (
        <div className={"hamburger-container " + (menuOpen && "active")}> {/* this means, if menuOpen = true, then add .active */}
            <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
        </div>
    )
}