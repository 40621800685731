import './Outline.scss';
import Hamburger from '../hamburger/Hamburger';
import Menu from "../menu/Menu";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";

export default function Outline() {
    
    const [menuOpen, setMenuOpen] = useState(false)
   
    return (
        <div className="intro" id="outline">
            <div className="left">
                <div className="left-hamburger-menu">
                    <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>
                <div className="img-container">
                    <img src="assets/DOP_Cover.png" alt="" />
                    <div className="expand-button">
                        <a href="#blurb">
                            <ExpandMoreIcon className="expand"/>
                        </a>
                    </div> 
                </div>
                
            </div>
            <div className="right" id="blurb">
                <div className="hamburger-menu">
                    <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>
                <div className="wrapper">
                    <h2>Descendants Of Power</h2>
                    <p>
                        is the story of two civilizations evolving in parallel over 100 years. One runs from its mistakes, 
                        while the other is forced to meet them head on - until the two meet their inevitable collision.
                    </p>
                    <img src="assets/Wind-Left.png" alt="" className="wind"/>
                    <p>
                        Who would be better off, those with the power and resources to ‘start fresh’, 
                        but with the same ideology that destroyed their home world? 
                        Or those who are left behind with little more than a crumbling planet and lessons learned?
                    </p>
                    <img src="assets/Wind-Right.png" alt="" className="wind"/>
                    <p>
                        A multi-generational saga that is not driven by any one of its extensive cast of characters but instead a unifying ideology. 
                        Descendants of Power is the narrative of a people divided and reunited again, 
                        and at its core the question: Can we overcome our own human nature, or are we destined to live as a warring and contentious species?
                    </p>
                    <a href="https://www.amazon.com/dp/B08RCCN9J8/ref=cm_sw_r_tw_dp_63EXGG3EZ0Q5AN9DDW04" target="_blank" rel="noreferrer">
                        <h1>
                            Get your copy.
                        </h1>
                    </a>
                    <div className="expand-button">
                        <a href="#signup">
                            <ExpandMoreIcon className="expand"/>
                        </a>
                    </div>
                </div>
            </div>
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
    )
}
